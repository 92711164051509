body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*enter competition css*/
.enter-competition {
  background-color: #ffe5b4;
  height: 100%;
  width: 100%;
}

.enter-competition iframe{
  height: 100%;
  width: 100%;
}

/*instructions overlay*/
.MuiModal-root .MuiBackdrop-root {
  background-image: url("../public/images/background.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

#actionButton {
  background-color: rgba(220, 111, 35, 1);
}
